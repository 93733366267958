import * as React from 'react';
import {debounce} from 'lodash';

import {Container, Form} from 'react-bootstrap';

const Search = ({updateSearchHandler}) => {
  const changeHandler = event => {
    if (updateSearchHandler) updateSearchHandler(event.target.value);
  };

  const debouncedSearchInputHandler = React.useCallback(debounce(changeHandler, 300), []);

  return (
    <Container>
      <div className="kola-naukowe__search">
        <Form.Control type="text" placeholder="Wyszukaj koła naukowe" onChange={debouncedSearchInputHandler} />
      </div>
    </Container>
  );
};

export default Search;
