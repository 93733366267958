import * as React from 'react';

import {Container, Button} from 'react-bootstrap';

const Filters = ({tags, updateFiltersHandler, updateAreasHandler}) => {
  const availableAreas = ['zielony', 'czarny', 'czerwony'];

  const [dispAll, setDispAll] = React.useState(false);
  const [tagsData, setTagsData] = React.useState([]);
  const [currentArea, setCurrentArea] = React.useState(null);

  const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  React.useEffect(() => {
    if (Array.isArray(tags) && tags?.length > 0) {
      setTagsData(tags.map(t => ({name: t, isActive: false})));
    }
  }, []);

  const tagClickHandler = index => {
    const tagsDataCpy = [...tagsData];
    tagsDataCpy[index].isActive = !tagsDataCpy[index].isActive;
    setTagsData(tagsDataCpy);
    updateFiltersHandler(tagsDataCpy.filter(t => t.isActive).map(t => t.name));
  };

  const renderTagsList = () => {
    const isActiveVariant = active => (active ? 'secondary' : 'outline-secondary');

    return tagsData.slice(0, dispAll ? tagsData.length : 10).map((tag, index) => (
      <Button variant={isActiveVariant(tag.isActive)} size="sm" key={index} onClick={() => tagClickHandler(index)}>
        #{tag.name}
      </Button>
    ));
  };

  const renderShowAllButton = () => {
    return (
      <Button variant="info" size="sm" onClick={() => setDispAll(true)}>
        Pokaż wszystkie tagi
      </Button>
    );
  };

  const renderAreasList = () => {
    return availableAreas.map(area => {
      return (
        <Button variant={getButtonVariant(area)} size="sm" onClick={() => areaButtonHandler(area)} key={area}>
          Obszar {capitalize(area)}
        </Button>
      );
    });
  };

  const areaButtonHandler = clickedVariant => {
    if (clickedVariant === currentArea) clickedVariant = null;
    setCurrentArea(clickedVariant);
    updateAreasHandler(clickedVariant);
  };

  const getButtonVariant = baseVariant => {
    const variantColor =
      baseVariant === 'zielony'
        ? 'success'
        : baseVariant === 'czarny'
        ? 'dark'
        : baseVariant === 'czerwony'
        ? 'danger'
        : 'default';
    if (currentArea === baseVariant) return variantColor;
    else return 'outline-' + variantColor;
  };

  return (
    <Container>
      <div className="kola-naukowe__filters">
        <div className="kola-naukowe__filters__area">{renderAreasList()}</div>
        <div className="kola-naukowe__filters__tags">
          {renderTagsList()}
          {!dispAll && renderShowAllButton()}
        </div>
      </div>
    </Container>
  );
};

export default Filters;
