import * as React from 'react';

const Header = () => {
  return (
    <div className="kola-naukowe__header">
      <h1>Wykaz Studenckich Kół Naukowych</h1>
    </div>
  );
};

export default Header;
